import { CompleteAccountModalContext, ModalContext } from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import { FavoriteButton } from '@liveauctioneers/hammer-ui-core/favoriteButton';
import { isItemSaved } from '@/redux/modules/item/saved/savedItems.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { toggleItemSave } from '@/redux/modules/item/saved/savedItems.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback, useMemo } from 'react';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation } from 'react-router-dom';
import { useTrackItemSave } from '@/hooks/useTrackItemSave';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';

type Props = {
    canShowEasyShipping?: boolean;
    isItemDetailChild?: boolean;
    itemId: number;
    labeled?: boolean;
};

export default function ItemCardSaveIcon({
    canShowEasyShipping = false,
    isItemDetailChild = false, // For tracking purposes we'd like to know if the save icon is on the item detail page or not
    itemId,
    labeled = false,
}: Props) {
    const saveButtonId = `saveBtn-${itemId}`;
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const isFavorite = useAppSelector((state) => isItemSaved(state, itemId));

    const modalContext = useMemo<ModalContext>(
        () => ({
            eventContext: EventContext.saveItem,
            id: itemId,
            itemId,
            type: CompleteAccountModalContext.SeeSoldPrice,
        }),
        [itemId]
    );

    const trackSave = useTrackItemSave({ isItemDetailChild, location });

    const onToggleSave = useCallback(() => {
        if (!isFavorite) {
            trackSave();
        }

        dispatch(toggleItemSave({ canHaveEasyShippingTag: canShowEasyShipping, itemId, location }));
    }, [isFavorite, dispatch, canShowEasyShipping, itemId, location, trackSave]);

    return (
        <ShouldLogInContainer
            forceSignUp={!userLoggedIn}
            modalContext={modalContext}
            runFunction={onToggleSave}
        >
            <FavoriteButton
                aria-label="favorite button"
                favorited={isFavorite}
                id={saveButtonId}
                tooltipProps={{
                    anchorSelect: `#${saveButtonId}`,
                    content: formatMessage({ id: 'save_to_my_items' }),
                    enabled: !isFavorite && labeled,
                    place: 'top',
                    positionStrategy: 'fixed',
                }}
                variant={labeled ? 'labeled' : 'circle'}
            />
        </ShouldLogInContainer>
    );
}
