import * as React from 'react';
import { CompleteAccountModalContext, openExcludeHouseModal, openRetractBidModal } from '@/redux/modules/modal';
import {
    createSaveSearchKeyBySearchParams,
    deleteSavedSearch,
    getIsSavedSearch,
    submitSaveSearch,
} from '@/redux/modules/saveSearch';
import { DEFAULT_STATUS_FOR_ONLINE_SEARCH } from '@/utils/queryParams';
import { DotsThreeOutlineVertical } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import {
    getIsTimedPlus,
    getItemModelCatalogStatus,
    getItemSummary,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { getSearchUrlWithParams } from '@/utils/urls';
import { ItemCardMenuClickTrigger, useTrackItemCardMenuClickAnalytics } from '@/utils/analytics';
import { toast } from '@liveauctioneers/hammer-ui-core/toast';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCanRetractTimedPlusAuctionBid } from '@/hooks/timedPlus/retractBid/useCanRetractTimedPlusAuctionBid';
import { useEffect, useState } from 'react';
import noop from '@/utils/noop';
import PopoverMenu, { Option, OptionLink } from '@liveauctioneers/caterwaul-components/lib/PopoverMenu/PopoverMenu';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';
import styled, { css } from 'styled-components';

export type ItemCardMenuProps = {
    itemId: number;
    showIcon?: boolean;
};

const ItemCardMenu = ({ itemId, showIcon = true }: ItemCardMenuProps) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const { bidderHasBid, catalogId, sellerId, title } = useAppSelector((state) => getItemSummary(state, itemId));
    const isTimedPlus = useAppSelector((state) => getIsTimedPlus(state, itemId));
    const catalogStatus = useAppSelector((state) => getItemModelCatalogStatus(state, itemId));
    const canRetractTimedPlusAuctionBid = useCanRetractTimedPlusAuctionBid(itemId);

    const trackItemCardMenuClickAnalytics = useTrackItemCardMenuClickAnalytics();

    const [canRetractBid, setCanRetractBid] = useState(false);
    const { approved, registered } = useAppSelector((state) => getCatalogRegistration(state, catalogId));

    useEffect(() => {
        const hasBid = (approved || registered) && bidderHasBid;
        const canRetractBasedOnCatalogType = isTimedPlus ? canRetractTimedPlusAuctionBid : catalogStatus !== 'live';
        setCanRetractBid(hasBid && canRetractBasedOnCatalogType);
    }, [approved, bidderHasBid, canRetractTimedPlusAuctionBid, catalogStatus, isTimedPlus, registered]);

    const showConfirmExcludeModal = React.useCallback(() => {
        trackItemCardMenuClickAnalytics({
            houseId: sellerId,
            itemId,
            trigger: ItemCardMenuClickTrigger.ExcludeHouseFromSearch,
        });
        dispatch(openExcludeHouseModal(sellerId));
    }, [dispatch, sellerId, trackItemCardMenuClickAnalytics, itemId]);

    const saveSearchParams = React.useMemo(() => {
        return { keyword: title, status: DEFAULT_STATUS_FOR_ONLINE_SEARCH };
    }, [title]);

    const itemSearchKeyword = saveSearchParams?.keyword;
    const saveSearchKey = createSaveSearchKeyBySearchParams(saveSearchParams);
    const isSavedSearch = useAppSelector((state) => getIsSavedSearch(state, saveSearchKey));

    const toggleSearchAlert = React.useCallback(() => {
        if (isSavedSearch) {
            dispatch(deleteSavedSearch(saveSearchParams));
            toast('success', {
                description: formatMessage({ id: 'searchRemoveAlertToast' }),
                title: formatMessage({ id: 'toastMessages.titles.success' }),
            });
        } else {
            dispatch(submitSaveSearch(saveSearchParams, true));
            toast('success', {
                description: formatMessage({ id: 'searchCreateAlertToast' }),
                title: formatMessage({ id: 'toastMessages.titles.success' }),
            });
        }
    }, [dispatch, formatMessage, isSavedSearch, saveSearchParams]);

    return (
        <PopoverMenu
            placement="left"
            trigger={
                <StyedTriggerWrapper
                    $showIcon={showIcon}
                    data-testid={`item-card-${itemId}-ellipsis`}
                >
                    <StyledKebabIconSmall
                        size="sm"
                        style="fill"
                    />
                </StyedTriggerWrapper>
            }
        >
            <OptionLink
                link={getSearchUrlWithParams({
                    keyword: itemSearchKeyword,
                    page: 1,
                    status: 'online',
                })}
            >
                <span
                    onClick={() =>
                        trackItemCardMenuClickAnalytics({
                            houseId: sellerId,
                            itemId,
                            trigger: ItemCardMenuClickTrigger.SeeSimilarItems,
                        })
                    }
                >
                    <FormattedMessage id="search_see_similar" />
                </span>
            </OptionLink>
            <ShouldLogInContainer
                modalContext={{
                    id: 1,
                    runAtLoginFunction: toggleSearchAlert,
                    type: CompleteAccountModalContext.LoginAndRunFunction,
                }}
            >
                <Option onClick={noop}>
                    <FormattedMessage id={isSavedSearch ? 'search_remove_alert' : 'search_create_alert'} />
                </Option>
            </ShouldLogInContainer>
            <ShouldLogInContainer
                modalContext={{
                    id: 2,
                    runAtLoginFunction: showConfirmExcludeModal,
                    type: CompleteAccountModalContext.LoginAndRunFunction,
                }}
            >
                <Option onClick={noop}>
                    <FormattedMessage id="search_exclude_this_auction_house" />
                </Option>
            </ShouldLogInContainer>
            {canRetractBid && (
                <Option
                    onClick={() => {
                        trackItemCardMenuClickAnalytics({
                            houseId: sellerId,
                            itemId,
                            trigger: ItemCardMenuClickTrigger.RetractBid,
                        });
                        dispatch(openRetractBidModal(itemId));
                    }}
                >
                    <FormattedMessage id="retract_bid" />
                </Option>
            )}
        </PopoverMenu>
    );
};

export default ItemCardMenu;

const StyledKebabIconSmall = styled(DotsThreeOutlineVertical)`
    &:hover {
        color: ${({ theme }) => theme.colors.blue200};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        display: inline-block;
    }
`;

const StyedTriggerWrapper = styled.span<{ $showIcon?: boolean }>`
    opacity: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    ${({ $showIcon }) =>
        $showIcon &&
        css`
            opacity: 1;
        `}
`;
