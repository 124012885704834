import * as React from 'react';
import { addImageParams, GetImageUrlParams } from '@/utils/urls';
import { DEFAULT_CARD_DIMENSIONS } from '../GridItemCard/Card/constants/defaultCardDimensions.constant';
import {
    getItemFirstImageUrl,
    getItemImageUrlByImageNumber,
    getItemModelSEOExcluded,
    getItemModelShortDescription,
    getItemModelTitle,
    getItemPhotos,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { Link } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import { useItemOrPriceResultLink } from '@/hooks/useItemOrPriceResultLink';
import { useNavigate } from 'react-router-dom';
import CardStatusBanner from './CardStatusBanner';
import ImageWithHover from '@/components/ImageWithHover/ImageWithHover';
import noop from '@/utils/noop';
import styled, { css } from 'styled-components';

type Props = {
    applyDimensionToUrl?: boolean;
    className?: string;
    /** Both the height and width of image */
    dimension?: number;
    imageQuality?: number;
    isCatalogPage?: boolean;
    itemId: number;
    lazyLoadImage?: boolean;
    noWidthLimit?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onNavigate?: (itemUrl?: string) => void;
    openOnBlank?: boolean;
    showBidStatus?: boolean;
    showImageBorder?: boolean;
    showImageWithHover?: boolean;
};

const ImageRow = ({
    applyDimensionToUrl,
    className,
    dimension,
    imageQuality,
    isCatalogPage,
    itemId,
    lazyLoadImage = true,
    noWidthLimit,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onNavigate,
    showBidStatus,
    showImageBorder,
    showImageWithHover = false,
}: Props) => {
    const photos = useAppSelector((state) => getItemPhotos(state, itemId));
    const title = useAppSelector((state) => getItemModelTitle(state, itemId));
    const shortDescription = useAppSelector((state) => getItemModelShortDescription(state, itemId));
    const firstPhoto = useAppSelector((state) => getItemFirstImageUrl(state, itemId));
    const itemUrl = useItemOrPriceResultLink(itemId, true);
    const alt = Boolean(shortDescription) ? `${title}: ${shortDescription}` : title;

    // to ensure we only get a second photo if there is one, we check first to see if there is a photo
    const secondPhoto = useAppSelector((state) =>
        getItemImageUrlByImageNumber(state, photos?.length > 1 ? itemId : undefined, photos?.[1])
    );

    const imageOptions: GetImageUrlParams = {};
    const imageSizeProps: { height: number; width: number } = {
        height: dimension || DEFAULT_CARD_DIMENSIONS.desktop,
        width: dimension || DEFAULT_CARD_DIMENSIONS.desktop,
    };
    if (noWidthLimit) {
        imageOptions.height = 170;
    } else {
        imageOptions.height = 310;
    }

    if (applyDimensionToUrl) {
        imageOptions.width = dimension;
        imageOptions.height = dimension;
    }

    const imageUrl = addImageParams({ ...imageOptions, quality: imageQuality, url: firstPhoto });

    let secondaryImageUrl;

    if (Boolean(secondPhoto)) {
        secondaryImageUrl = addImageParams({
            ...imageOptions,
            url: secondPhoto,
        });
    }

    let cardImage = (
        <StyledImage
            $noWidthLimit={noWidthLimit}
            alt={alt}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
            src={imageUrl}
            {...imageSizeProps}
        />
    );

    if (Boolean(showImageWithHover) && Boolean(secondPhoto)) {
        cardImage = (
            <ImageWithHover
                alt={alt}
                lazyLoadImage={lazyLoadImage}
                srcPrimary={imageUrl}
                srcSecondary={secondaryImageUrl}
                {...imageSizeProps}
            />
        );
    }

    const navigate = useNavigate();
    const isSeoExcluded = useAppSelector((state) => getItemModelSEOExcluded(state, itemId));

    return (
        <ContainerLink
            $catalogCard={isCatalogPage}
            $noWidthLimit={noWidthLimit}
            $showImageBorder={showImageBorder}
            className={className}
            data-testid="itemCard"
            href={itemUrl}
            onClick={(event) => {
                event.preventDefault();
                onNavigate?.(itemUrl);
                navigate(itemUrl);
            }}
            rel={isSeoExcluded ? 'nofollow' : ''}
        >
            <ImageContainerDiv
                $isCatalogPage={isCatalogPage}
                $noWidthLimit={noWidthLimit}
                data-testid="itemCardImage"
            >
                <ImageWithHoverDiv
                    $noWidthLimit={noWidthLimit}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {cardImage}
                </ImageWithHoverDiv>
                {showBidStatus && <CardStatusBanner itemId={itemId} />}
            </ImageContainerDiv>
        </ContainerLink>
    );
};

export default React.memo<Props>(ImageRow);

const ContainerLink = styled(Link)<{
    $catalogCard?: boolean;
    $noWidthLimit: boolean;
    $showImageBorder: boolean;
}>`
    text-align: center;
    cursor: pointer;
    display: block;
    margin: 0 auto 7px;

    /* width */
    ${({ $noWidthLimit }) =>
        $noWidthLimit &&
        css`
            width: fit-content;
        `}
    ${({ $catalogCard }) =>
        $catalogCard &&
        css`
            margin: 0;
            @media (max-width: 1200px) {
                width: unset;
            }
        `}



    @media print {
        max-width: 100px;
        max-height: 100px;
        width: unset;
        margin-right: 10px;
    }

    /* border */
    ${({ $showImageBorder, theme }) =>
        $showImageBorder &&
        css`
            border: 1px solid ${theme.colors.grey400};
        `}

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) {
        margin-bottom: 0;
    }
`;

const ImageContainerDiv = styled.div<{
    $isCatalogPage?: boolean;
    $noWidthLimit: boolean;
}>`
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    min-width: 126px;
    max-height: 100%;
    transition: all 0.25s;
    display: flex;
    justify-content: center;
    ${({ $noWidthLimit }) =>
        $noWidthLimit
            ? css`
                  height: 170px;
                  width: auto;
              `
            : css`
                  padding-bottom: 100%;
              `}
`;

const ImageWithHoverDiv = styled.div<{ $noWidthLimit: boolean }>`
    ${({ $noWidthLimit }) =>
        $noWidthLimit &&
        css`
            height: 170px;
            width: auto;
            display: flex;
            justify-content: center;
        `}
`;

const StyledImage = styled.img<{ $noWidthLimit: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    inset: 0;
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
    filter: blur(0);

    @media print {
        margin: 0;
    }

    ${({ $noWidthLimit }) =>
        $noWidthLimit &&
        css`
            margin: auto;
            position: relative;
            display: block;
            filter: blur(0);
            width: auto;
            max-width: 500px;
            max-height: 170px;
        `}
`;
