import * as React from 'react';
import styled, { css } from 'styled-components';

type Props = {
    alt: string;
    className?: string;
    lazyLoadImage?: boolean;
    srcPrimary: string;
    srcSecondary: string;
};

const ImageWithHover = ({
    alt = '',
    className = '',
    lazyLoadImage = true,
    srcPrimary = '',
    srcSecondary = '',
    ...rest
}: Props) => {
    const [hover, setHover] = React.useState(false);
    const [primaryError, setPrimaryError] = React.useState(false);
    const [secondaryError, setSecondaryError] = React.useState(false);

    const handleMouseEnter = React.useCallback(() => {
        setHover(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setHover(false);
    }, []);

    const handlePrimaryError = React.useCallback(() => {
        setPrimaryError(true);
    }, []);

    const handleSecondaryError = React.useCallback(() => {
        setSecondaryError(true);
    }, []);

    let source = srcPrimary ? srcPrimary : srcSecondary;
    let showSingleImage = false;

    if (primaryError) {
        if (!secondaryError) {
            showSingleImage = true;
            source = srcSecondary;
        }
    }

    if (secondaryError) {
        if (!primaryError) {
            showSingleImage = true;
            source = srcPrimary;
        }
    }

    if (showSingleImage) {
        return (
            <div className={className}>
                <DefaultImage
                    alt={alt}
                    src={source}
                />
            </div>
        );
    }

    const loading = lazyLoadImage ? 'lazy' : 'eager';

    return (
        <div
            className={className}
            data-testid="imageWithHover"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <SecondaryImageContainer $hover={hover}>
                {hover && (
                    <DefaultImage
                        alt={alt}
                        loading={loading}
                        onError={handleSecondaryError}
                        src={srcSecondary}
                        {...rest}
                    />
                )}
            </SecondaryImageContainer>
            <PrimaryImage
                $usePrimaryStyle={Boolean(srcSecondary) && !secondaryError}
                alt={alt}
                loading={loading}
                onError={handlePrimaryError}
                src={source}
                {...rest}
            />
        </div>
    );
};

export default React.memo<Props>(ImageWithHover);

const DefaultImage = styled.img`
    object-fit: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
    @media print {
        margin: 0;
    }
`;

interface PrimaryProps {
    $usePrimaryStyle: boolean;
}

const PrimaryImage = styled.img<PrimaryProps>`
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
    @media print {
        margin: 0;
    }

    &:hover {
        opacity: ${({ $usePrimaryStyle }) => ($usePrimaryStyle ? 0 : 1)};
        transition: ${({ $usePrimaryStyle }) => ($usePrimaryStyle ? 'opacity 0.8s ease-in-out' : 'none')};
        transition-delay: ${({ $usePrimaryStyle }) => ($usePrimaryStyle ? '0.3s' : '0s')};
    }
`;

const onHover = css`
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    transition-delay: 0.3s;
`;

const SecondaryImageContainer = styled.div<{ $hover: boolean }>`
    opacity: 0;
    ${(props) => props.$hover && onHover}
`;
