import {
    isCatalogPage,
    isCategoryPage,
    isForYouPage,
    isItemPage,
    isPriceGuidePage,
    isPriceResultsPage,
    isSearchPage,
} from '@/utils/urls';
import { Location } from 'react-router-dom';
import { useCallback } from 'react';
import { useSaveIconOnLotDetailImageClicked } from '@/hooks/kameleoon/tracking/useSaveIconOnLotDetailImageClicked';
import { useTrackItemSaved } from '@/hooks/kameleoon/tracking/useTrackItemSaved';
import { useTrackItemSavedCatalogPage } from '@/hooks/kameleoon/tracking/useTrackItemSavedCatalogPage';
import { useTrackItemSavedItemPage } from '@/hooks/kameleoon/tracking/useTrackItemSavedItemPage';
import { useTrackSavedItemCardCategoryPage } from '@/hooks/kameleoon/tracking/useTrackSavedItemCardCategoryPage';
import { useTrackSavedPriceResultsItem } from '@/hooks/kameleoon/tracking/useTrackPriceResultsPage';
import { useTrackSavedSearchItem } from '@/hooks/kameleoon/tracking/useTrackSavedSearchItem';

interface UseTrackItemSaveProps {
    isItemDetailChild?: boolean;
    location: Location;
}

export const useTrackItemSave = ({ isItemDetailChild, location }: UseTrackItemSaveProps) => {
    const trackItemSaved = useTrackItemSaved();
    const trackItemSavedCatalogPage = useTrackItemSavedCatalogPage();
    const trackItemSavedItemPage = useTrackItemSavedItemPage();
    const trackSavedItemCardCategoryPage = useTrackSavedItemCardCategoryPage();
    const trackSavedPriceResultsItem = useTrackSavedPriceResultsItem();
    const trackSavedSearchItem = useTrackSavedSearchItem();
    const trackSaveIconOnLotDetailImageClicked = useSaveIconOnLotDetailImageClicked();

    return useCallback(() => {
        const isOnItemPage = isItemPage(location.pathname);
        const isOnCategoryPage = isCategoryPage(location.pathname);
        const isOnSearchPage = isSearchPage(location.pathname);
        const isOnForYouPage = isForYouPage(location.pathname);
        const isOnCatalogPage = isCatalogPage(location.pathname);
        const isOnPriceGuidePage = isPriceGuidePage(location.pathname);
        const isOnPriceResultsPage = isPriceResultsPage(location.pathname);
        const shouldTrackSavedSearchItem = isOnSearchPage || isOnForYouPage;

        trackItemSaved();

        if (isOnItemPage) {
            trackItemSavedItemPage();
        }

        if (isOnItemPage && isItemDetailChild) {
            trackSaveIconOnLotDetailImageClicked();
        }

        if (isOnCatalogPage) {
            trackItemSavedCatalogPage();
        }

        if (isOnPriceResultsPage) {
            trackSavedPriceResultsItem();
        }

        if (isOnCategoryPage || isOnPriceGuidePage) {
            trackSavedItemCardCategoryPage();
        }

        if (shouldTrackSavedSearchItem) {
            trackSavedSearchItem();
        }
    }, [
        location.pathname,
        trackItemSaved,
        trackItemSavedItemPage,
        isItemDetailChild,
        trackSaveIconOnLotDetailImageClicked,
        trackItemSavedCatalogPage,
        trackSavedPriceResultsItem,
        trackSavedItemCardCategoryPage,
        trackSavedSearchItem,
    ]);
};
