import {
    getIsTimedPlus,
    getItemCurrentTimedPlusEndTime,
    getItemModelIsPaused,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import ms from 'ms';

const TWENTY_FOUR_DURATION_IN_SECONDS = ms('24h') / 1000;

export const useCanRetractTimedPlusAuctionBid = (itemId: number): boolean => {
    const isTimedPlusAuction = useAppSelector((state) => getIsTimedPlus(state, itemId));
    const isLotWithdrawn = useAppSelector((state) => getItemModelIsPaused(state, itemId));
    const lotEndTime = useAppSelector((state) => getItemCurrentTimedPlusEndTime(state, itemId));
    // lotEndTime is in seconds
    const isWithin24Hours = lotEndTime - TWENTY_FOUR_DURATION_IN_SECONDS <= Date.now() / 1000;
    const [canRetract, setCanRetract] = useState(isTimedPlusAuction && !isWithin24Hours);

    useEffect(() => {
        // lotEndTime is in seconds
        const isWithin24Hours = lotEndTime - TWENTY_FOUR_DURATION_IN_SECONDS <= Date.now() / 1000;
        setCanRetract(isTimedPlusAuction && !isWithin24Hours && !isLotWithdrawn);
    }, [isLotWithdrawn, isTimedPlusAuction, lotEndTime]);

    return canRetract;
};
